<template>
   <JoditEditor v-model="valueEditor" :config="config" />
</template>

<script>

import { mapState } from 'vuex'
import 'jodit/es5/jodit.min.css';
import { JoditEditor } from 'jodit-vue'

export default {
   name: 'EditorHTML',
   props: ['value'],
   data: function () {
      return {
         valueEditor: this.value
      }
   },
   computed: {
		...mapState({
			dadosUsuario: state => state.dadosUsuario,
			tokenSessao: state => state.tokenSessao,
			urlRest: state => state.urlRest
		}),
      config : function () {
         return {
            minHeight: 250,
            allowResizeX: false,
            askBeforePasteHTML: false,
            saveHeightInStorage: false,
            hidePoweredByJodit: true,
            language: "pt_br",
            enterBlock: "div",
            enableDragAndDropFileToEditor: false,
            // colorPickerDefaultTab: "color",
            buttons: "bold,italic,underline,strikethrough,eraser,ul,ol,font,fontsize,paragraph,lineHeight,superscript,subscript,image,video,spellcheck,cut,copy,paste,undo,redo,align",
            disablePlugins: "image-properties,color",
            uploader: {
               url: this.urlRest +'configuracoes/uploadArquivo',
               headers: {'Authorization' : 'Bearer '+ this.tokenSessao +'@@@'+ this.dadosUsuario.usuarioEmail},
               format: 'json',
               filesVariableName : function () {
                  return 'fileAdm';
               },
               getMessage: function () {
                  return 'Arquivo(s) enviado(s)';
               },
               isSuccess: function () {
                  return true;
               },
               process: (resp) => {
                  return {files: resp};
               },
               defaultHandlerSuccess: function (data) {
                  var i, field = 'files';

                  if (data[field] && data[field].length) {
                     for (i = 0; i < data[field].length; i += 1) {
                        this.s.insertImage(data[field][i].url);
                     }
                  }
               },
               error: (e) => {
                  console.error('Upload error', e);
               }
            }
         }
      }
	},
   watch: {
      value : function (val) {
         this.valueEditor = val
      },
      valueEditor : function (val) {
         this.$emit('attValue', val)
      }
   },
   components: {
      JoditEditor
   }
}

</script>
 